<template>
  <ion-split-pane content-id="main-content" when="true">
    <ion-menu v-if="isMenuOpen" class="menuOptions" type="overlay" side="start" content-id="main-content"
      maxEdgeStart="50" style="margin-top: 46px">
      <ion-content :scroll-events="true" background-color="primary" color="primary">
        <div id="header">
          <ion-row>
            <ion-menu-toggle auto-hide="false" color="primary" background-color="primary">
              <ion-button fill="clear">
                <ion-icon :ios="arrowBackOutline" :md="arrowBackOutline"></ion-icon>
              </ion-button>
            </ion-menu-toggle>
          </ion-row>

          <ion-row>
            <ion-text class="marginBottom4px marginLeft2vh boldAndLarge ion-text-capitalize" color="dark">
              {{ user.nombre + " " + user.apellido }}
            </ion-text>
          </ion-row>
          <ion-row>
            <ion-text color="medium" class="marginBottom12px marginLeft2vh">
              {{ user.email }}
            </ion-text>
          </ion-row>
        </div>
        <ion-list id="inbox-list">
          <ion-menu-toggle auto-hide="false" color="primary" background-color="primary" v-for="(p, i) in productorPages"
            :key="i">
            <ion-item @click="handleMenuItemClick(p.url, i)" :router-link="p.url" lines="none" detail="false"
              class="hydrated" :class="{ selected: isSelected(p.url) }">
              <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <ion-list id="logout-list">
          <ion-item router-direction="root" router-link="/logout" lines="none" detail="false" class="hydrated">
            <ion-icon slot="start" :ios="logOutOutline" :md="logOutSharp"></ion-icon>
            <ion-label>Cerrar Sesión</ion-label>
          </ion-item>
        </ion-list>
        <br />
        <br />
      </ion-content>
    </ion-menu>

    <ion-router-outlet :style="`margin-top: 56px; ${$route.name !== 'persona-mapa' ? 'margin-bottom:56px' : '0px'}`"
      id="main-content" />
  </ion-split-pane>
</template>

<script>
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonButton,
  IonText,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import {
  logOutOutline,
  logOutSharp,
  arrowBackOutline,
  people,
  list,
  statsChart,
  bulbOutline,
  businessOutline,
  person,
  lockClosed
} from "ionicons/icons";
import { mapState, mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: "App",
  components: {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonText,
    IonButton,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane
  },
  setup() {
    const selectedIndex = ref(0);
    const isMenuOpen = ref(true);
    const route = useRoute();

    return {
      route,
      selectedIndex,
      isMenuOpen,
      logOutOutline,
      logOutSharp,
      arrowBackOutline,
      people,
      list,
      statsChart,
      bulbOutline,
      businessOutline,
      person,
      lockClosed,
      isSelected: url => (url === route.path ? "selected" : "")
    };
  },

  computed: {
    ...mapState(["user"]),
    productorPages() {
      const pages = [
        {
          title: "Mis Lotes",
          url: "/lotes",
          iosIcon: list,
          mdIcon: list
        },
        {
          title: "Mis Establecimientos",
          url: "/establecimientos",
          iosIcon: businessOutline,
          mdIcon: businessOutline
        },
        {
          title: "Informes",
          url: "/informes",
          iosIcon: statsChart,
          mdIcon: statsChart
        },
        {
          title: "Mis Datos",
          url: "/datos",
          iosIcon: person,
          mdIcon: person
        },
        {
          title: "Sugerencias",
          url: "/sugerencias",
          iosIcon: bulbOutline,
          mdIcon: bulbOutline
        }
      ];

      if (this.isAdmin()) {
        pages.unshift({
          title: "Productores",
          url: "/productores",
          iosIcon: people,
          mdIcon: people
        });
      }

      if (
        this.user.provider === "local" &&
        (!this.user.isAapresidUser || this.user.isAapresidUser === false)
      ) {
        pages.push({
          title: "Cambiar Contraseña",
          url: "/password",
          iosIcon: lockClosed,
          mdIcon: lockClosed
        });
      }

      return pages;
    }
  },

  methods: {
    ...mapActions(["getUserAdmin"]),
    ...mapGetters(["isAdmin"]),
    handleMenuItemClick(url, index) {
      if (this.route.path !== url) {
        this.selectedIndex = index;
        this.isMenuOpen = false;
      }
    }
  },

  async mounted() {
    await this.getUserAdmin();
  }
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  pointer-events: fill;
}

ion-item ion-label {
  cursor: pointer;
}

ion-item {
  pointer-events: auto !important;
}

ion-split-pane {
  --side-width: 0px !important;
}

ion-list-header.md {
  margin-top: 10px;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

#header {
  background-color: white;
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
  margin-bottom: 2px;
  padding-top: 0px;
}

.marginBottom4px {
  margin-bottom: 4px;
}

.marginBottom12px {
  margin-bottom: 12px;
}

.marginLeft2vh {
  margin-left: 2vh;
}

.boldAndLarge {
  font-weight: bold;
  font-size: x-large;
}

@media only screen and (min-width: 1024px) {
  #logout-list {
    padding-top: 10px !important;
  }
}
</style>